<template>
  <el-collapse v-model="filter" @change="handleChange">
    <el-collapse-item title="Фильтр" name="p1">
        <template v-slot:title>
          <span>
            <span>Фильтр</span>
            <el-button class="ml-5"
                plain
                size="mini"
                type="default" 
                @click.stop="clearFilters" 
                style="font-family: Montserrat-Medium !important;">
              Очистить фильтры
            </el-button>
          </span>
        </template>
      <el-row :gutter="5" class="mt-0 filter">
        <el-col :span="6" class="ml-2 filter--container">Наименование:</el-col>
        <el-col :span="6" class="ml-0 filter--container">Энергосистема:</el-col>
        <el-col :span="6" class="ml-0 filter--container">Энергообъект:</el-col>
        <el-col :span="6" class="ml-0 filter--container">Владелец/эксплуатирующая организация:</el-col>
      </el-row>

      <el-row class="m-0 filter">
        <el-col :span="6" class="ml-2 filter--container middle-align">
          <!-- <span class="mr-2">Наименование:</span> -->
          <remote-search-input
            v-model="filterModel.nameFilter"
            searchUrl="/api/secondEquipments/SearchByName"
            :start-length=3
          />
        </el-col>

        <el-col :span="6" class="ml-2 filter--container big-label">
          <!-- <span class="mr-2 mt-1">Энергосистема:</span> -->
          <!-- <remote-search-input size="small"
              v-model="filterModel.eoRegionFilter"
              searchUrl="/api/regions/SearchByName"
              :allow-create=false
              :multiple=true
              :start-length=3
              returnProp="id"
            /> -->
          <el-select multiple filterable clearable size="small"
                     ref="eoRegionFilterSelector"
                     :collapse-tags="filterModel.eoRegionFilter.length > 5"
                     v-model="filterModel.eoRegionFilter"
                     popper-class="search-select">
            <div class="pl-3">
              <el-button type="text" size="small"
                         @click="filterModel.eoRegionFilter = getSelectedIdsByName(operationalZones, $refs.eoRegionFilterSelector.query)">
                Выбрать всё
              </el-button>
            </div>
            <el-option
              v-for="item in operationalZones"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-col>

        <el-col :span="6" class="ml-2 filter--container">
          <!-- <span class="mr-2">Энергообъект:</span> -->
          <remote-search-input
            v-model="filterModel.energyObjectNameFilter"
            searchUrl="/api/SecondEquipments/SearchEnergyObjectsByName"
            :allow-create=false
            :start-length=3
            :multiple=true
          />
        </el-col>

        <el-col :span="6" class="ml-2 filter--container">
          <!-- <span style="min-width:13rem;">Владелец / эксплуатирующая организация:</span> -->
          <remote-search-input
            v-model="filterModel.ownerOrganizationsFilter"
            searchUrl="/api/Organizations/SearchByOwner"
            :allow-create=false
            :multiple=true
            :start-length=1
            returnProp="id"
          />
        </el-col>
      </el-row>

      <el-row :gutter="5" class="mt-2 filter">
        <el-col :span="6" class="ml-2 filter--container">Диспетчерское управление:</el-col>
        <el-col :span="6" class="ml-0 filter--container">Диспетчерское ведение:</el-col>
        <el-col :span="6" class="ml-0 filter--container">Технологическое управление:</el-col>
        <el-col :span="6" class="ml-0 filter--container">Технологическое ведение:</el-col>
      </el-row>

      <el-row class="mt-0 filter">
        <el-col :span="6" class="ml-2 filter--container">
          <!-- <span class="mr-2 mt-1">Диспетчерское управление:</span> -->
          <!-- <remote-search-input size="small"
            v-model="filterModel.dispatchAdministrationOrganizationsFilter"
            searchUrl="/api/organizations/SearchByNameAndRole?roleName=Диспетчерское управление"
            :allow-create=false
            :multiple="true"
            returnProp="id"
          /> -->
          <el-select multiple filterable clearable size="small"
                     ref="duOrganizationsSelector"
                     :collapse-tags="filterModel.dispatchAdministrationOrganizationsFilter.length > 2"
                     v-model="filterModel.dispatchAdministrationOrganizationsFilter"
                     popper-class="search-select">
            <div class="pl-3">
              <el-button type="text" size="small"
                         @click="filterModel.dispatchAdministrationOrganizationsFilter = getSelectedValues(duOrganizations, $refs.duOrganizationsSelector.query, (item) => (item.value), (item) => (item.id))">
                Выбрать всё
              </el-button>
            </div>
            <el-option
              v-for="item in duOrganizations"
              :key="item.id"
              :label="item.value"
              :value="item.id">
            </el-option>
          </el-select>
        </el-col>

        <el-col :span="6" class="ml-2 filter--container">
          <!-- <span class="mr-2 mt-1">Диспетчерское ведение:</span> -->
          <!-- <remote-search-input size="small"
            v-model="filterModel.dispatchManagementOrganizationsFilter"
            searchUrl="/api/organizations/SearchByNameAndRole?roleName=Диспетчерское ведение"
            :allow-create=false
            :multiple="true"
            returnProp="id"
          /> -->
          <el-select multiple filterable clearable size="small"
                     ref="dvOrganizationsSelector"
                     :collapse-tags="filterModel.dispatchManagementOrganizationsFilter.length > 2"
                     v-model="filterModel.dispatchManagementOrganizationsFilter"
                     popper-class="search-select">
            <div class="pl-3">
              <el-button type="text" size="small"
                         @click="filterModel.dispatchManagementOrganizationsFilter = getSelectedValues(dvOrganizations, $refs.dvOrganizationsSelector.query, (item) => (item.value), (item) => (item.id))">
                Выбрать всё
              </el-button>
            </div>
            <el-option
              v-for="item in dvOrganizations"
              :key="item.id"
              :label="item.value"
              :value="item.id">
            </el-option>
          </el-select>
        </el-col>

        <el-col :span="6" class="ml-2 filter--container">
          <!-- <span class="mr-2 mt-1">Технологическое управление:</span> -->
          <!-- <remote-search-input size="small"
            v-model="filterModel.techAdministrationOrganizationsFilter"
            searchUrl="/api/organizations/SearchByNameAndRole?roleName=Технологическое управление"
            :allow-create=false
            :start-length=3
            :multiple="true"
            returnProp="id"
          /> -->
          <el-select multiple filterable clearable size="small"
                     ref="taOrganizationsSelector"
                     :collapse-tags="filterModel.techAdministrationOrganizationsFilter.length > 2"
                     v-model="filterModel.techAdministrationOrganizationsFilter"
                     popper-class="search-select">
            <div class="pl-3">
              <el-button type="text" size="small"
                         @click="filterModel.techAdministrationOrganizationsFilter = getSelectedValues(techAdministrationOrganizations, $refs.taOrganizationsSelector.query, (item) => (item.value), (item) => (item.id))">
                Выбрать всё
              </el-button>
            </div>
            <el-option v-for="item in techAdministrationOrganizations"
                       :key="item.id"
                       :label="item.value"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-col>

        <el-col :span="6" class="ml-2 filter--container">
          <!-- <span class="mr-2 mt-1">Технологическое ведение:</span> -->
          <!-- <remote-search-input size="small"
            v-model="filterModel.techManagementOrganizationsFilter"
            searchUrl="/api/organizations/SearchByNameAndRole?roleName=Технологическое ведение"
            :allow-create=false
            :start-length=3
            :multiple="true"
            returnProp="id"
          /> -->
          <el-select multiple filterable clearable size="small"
                     ref="tmOrganizationsSelector"
                     :collapse-tags="filterModel.techManagementOrganizationsFilter.length > 2"
                     v-model="filterModel.techManagementOrganizationsFilter"
                     popper-class="search-select">
            <div class="pl-3">
              <el-button type="text" size="small"
                         @click="filterModel.techManagementOrganizationsFilter = getSelectedValues(techManagementOrganizations, $refs.tmOrganizationsSelector.query, (item) => (item.value), (item) => (item.id))">
                Выбрать всё
              </el-button>
            </div>
            <el-option v-for="item in techManagementOrganizations"
                       :key="item.id"
                       :label="item.value"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-col>
      </el-row>

      <el-row :gutter="5" class="mt-0 filter">
        <el-col :span="6" class="ml-2 filter--container">Первичное оборудование/ЛЭП:</el-col>
        <el-col :span="6" class="ml-0 filter--container">Исполнение:</el-col>
        <el-col :span="6" class="ml-0 filter--container">Группы учета:</el-col>
        <el-col :span="6" class="ml-0 filter--container">Технический учёт ДЦ:</el-col>
      </el-row>

      <el-row class="mt-0 filter">
        <el-col :span="6" class="ml-2 filter--container">
          <!-- <span class="mr-2 mt-1">Первичное оборудование:</span> -->
          <remote-search-input
            v-model="filterModel.primaryEquipmentNameFilter"
            searchUrl="/api/primaryEquipments/SearchByName"
            :allow-create=false
            :start-length=3
            :multiple="true"
          />
        </el-col>

        <el-col :span="6" class="ml-2 filter--container">
          <!-- <span class="mr-2" style="min-width:13rem;">Исполнение:</span> -->
          <el-select multiple filterable clearable size="small"
                     ref="executionsSelector"
                     :collapse-tags="filterModel.executionsFilter.length > 5"
                     v-model="filterModel.executionsFilter"
                     popper-class="search-select">
            <div class="pl-3">
              <el-button type="text" size="small"
                         @click="filterModel.executionsFilter = getSelectedValues(executions, $refs.executionsSelector.query, (item) => (item.code), (item) => (item.id))">
                Выбрать всё
              </el-button>
            </div>
            <el-option v-for="item in executions"
                       :key="item.id"
                       :label="item.code"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-col>

        <el-col :span="6" class="ml-2 filter--container">
          <!-- <span class="mr-2" style="min-width:13rem;">Группы учета:</span> -->
          <el-select multiple filterable clearable size="small"
                     ref="registrationGroupSelector"
                     :collapse-tags="filterModel.registrationGroupFilter.length > 5"
                     v-model="filterModel.registrationGroupFilter">
              <div class="pl-3">
                <el-button type="text" size="small"
                           @click="filterModel.registrationGroupFilter = getSelectedIdsByName(registrationGroupsWithNotMfu, $refs.registrationGroupSelector.query)">
                  Выбрать всё
                </el-button>
              </div>
              <el-option
                v-for="item in registrationGroupsWithNotMfu"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
        </el-col>

        <el-col :span="6" class="ml-2 filter--container">
            <!-- <span class="mr-2" style="min-width:13rem;">Технический учёт ДЦ:</span> -->
            <!-- <remote-search-input size="small"
              v-model="filterModel.technicalOrganizationsFilter"
              searchUrl="/api/organizations/SearchByNameAndRole"
              :allow-create=false
              :start-length=3
              :multiple="true"
              returnProp="id"
            /> -->
            <el-select multiple filterable clearable size="small"
                       ref="techOrganizationsSelector"
                       :collapse-tags="filterModel.technicalOrganizationsFilter.length > 2"
                       v-model="filterModel.technicalOrganizationsFilter"
                       popper-class="search-select">
              <div class="pl-3">
                <el-button type="text" size="small"
                           @click="filterModel.technicalOrganizationsFilter = getSelectedIdsByName(organizations, $refs.techOrganizationsSelector.query)">
                  Выбрать всё
                </el-button>
              </div>
            <el-option
              v-for="item in organizations"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-col>
      </el-row>

      <el-row :gutter="5" class="mt-0 filter">
        <el-col :span="6" class="ml-2 filter--container">Устройство РЗА:</el-col>
        <el-col :span="6" class="ml-0 filter--container">Период ввода в эксплуатацию:</el-col>
        <el-col :span="6" class="ml-0 filter--container">Период вывода из эксплуатации:</el-col>
        <el-col :span="6" class="ml-0 filter--container">Отобр. выведенные из экспл. функции:</el-col>
      </el-row>

      <el-row class="mt-0 filter">
        <el-col :span="6" class="ml-2 filter--container">
          <!-- <span class="mr-2 mt-1">Устройство РЗА:</span> -->
          <remote-search-input
            v-model="filterModel.secondEquipmentPanelNameFilter"
            searchUrl="/api/secondEquipmentPanels/SearchByName"
            :allow-create=false
            :start-length=3
            :multiple=true
          />
        </el-col>

        <el-col :span="6" class="ml-2 filter--container">
          <!-- <span class="mr-2" style="min-width:13rem;">Период ввода в эксплуатацию:</span> -->
          <el-date-picker
           size="small"
            v-model="filterModel.periodCommissioningTimeRange"
            type="daterange"
            range-separator="-"
            placeholder="дд.мм.гггг"
            format="dd.MM.yyyy"
            start-placeholder="с"
            end-placeholder="по">
          </el-date-picker>
        </el-col>

        <el-col :span="6" class="ml-2 filter--container">
          <!-- <span class="mr-2" style="min-width:13rem;">Период вывода из эксплуатации:</span> -->
          <el-date-picker
            size="small"
            v-model="filterModel.periodDecommissioningTimeRange"
            type="daterange"
            range-separator="-"
            placeholder="дд.мм.гггг"
            format="dd.MM.yyyy"
            start-placeholder="с"
            end-placeholder="по">
          </el-date-picker>
        </el-col>

        <el-col :span="6" class="ml-2 filter--container">
          <!-- <span class="mr-2" style="min-width:13rem;">Отобр. выведенные из экспл. устройства:</span> -->
          <el-select v-model="filterModel.showDecommissionedSE" size="small">
            <el-option
              v-for="item in decommissionedSE"
              :key="item.id"
              :label="item.name"
              :value="item.code">
            </el-option>
          </el-select>
        </el-col>
      </el-row>

      <el-row :gutter="5" class="mt-0 filter">
        <el-col :span="6" class="ml-2 filter--container">Тип:</el-col>
        <el-col :span="6" class="ml-0 filter--container">Отображать объекты локальной НСИ:</el-col>
        <el-col :span="6" class="ml-0 filter--container" v-if="showAllFilter">
          <span v-if="userHasRoleGroup('ExternalUser')">Показать все доступные функции:</span>
          <span v-else>Показать все функции ОЗ ДЦ</span>
        </el-col>
        <el-col :span="6" class="ml-0 filter--container" v-else></el-col>
        <el-col :span="6" class="ml-0 filter--container"></el-col>
      </el-row>

      <el-row class="mt-0 filter">
        <el-col :span="6" class="ml-2 filter--container">
          <!-- <span class="mr-2 mt-1">Тип:</span> -->
          <el-select multiple filterable clearable size="small"
                    ref="sePsrTypesSelector"
                    :collapse-tags="filterModel.psrTypeIdsFilter.length > 5"
                    v-model="filterModel.psrTypeIdsFilter"
                    popper-class="search-select">
            <div class="pl-3">
              <el-button type="text" size="small"
                        @click="filterModel.psrTypeIdsFilter = getSelectedValuesByName(secondEquipmentPsrTypes, $refs.sePsrTypesSelector.query)">
                Выбрать всё
              </el-button>
            </div>
            <el-option
              v-for="item in secondEquipmentPsrTypes"
              :key="item.id"
              :label="item.name"
              :value="item.name">
            </el-option>
          </el-select>
        </el-col>

        <el-col :span="6" class="ml-2 filter--container">
          <!-- <span class="mr-2 mt-1">Отображать объекты локальной НСИ:</span> -->
          <el-switch style="width: 100%;"
                v-model="filterModel.isLocalNsiFilter"
                active-color="#13ce66"
                inactive-color="#ff4949"
              />
        </el-col>

        <el-col :span="6" class="ml-2 filter--container">
          <el-switch v-if="showAllFilter" style="width: 100%;"
                v-model="filterModel.showAll"
                active-color="#13ce66"
                inactive-color="#ff4949"
              />
          <!-- <span class="mr-2 mt-1">
            <el-checkbox v-model="filterModel.showAll" /></span> -->
        </el-col>

        <el-col :span="6" class="ml-2 filter--container">
          <span class="mr-2"></span>
        </el-col>
      </el-row>
      <!-- <el-row class="m-0 filter">
        <el-col :span="8" class="ml-2 filter--container">
          <el-button @click="clearFilters" type="text" style="font-family: Montserrat-Medium !important;">
            Очистить фильтры
          </el-button>
        </el-col>
      </el-row> -->
    </el-collapse-item>
  </el-collapse>
</template>

<script>
import { mapGetters } from 'vuex';
import RemoteSearchInput from '@/components/Ui/RemoteSearchInput';
import UserSettingsService from '@/service/userSettingsService';
import executions from '@/api/nsi/executions';
import regApi from '@/api/administration/userRegistration';
import organizationsApi from '@/api/nsi/organizations';
import filterHelper from '@/mixins/filterHelper';

export default {
  name: 'SecondEquipmentsFilter',
  props: {
    filterModel: { type: Object, default: () => {} },
    showAllFilter: { type: Boolean, default: () => false },
  },
  mixins: [filterHelper],
  components: { RemoteSearchInput },
  data() {
    return {
      filter: [''],
      executions: [],
      organizations: [],
      techAdministrationOrganizations: [],
      techManagementOrganizations: [],
      dvOrganizations: [],
      duOrganizations: []
    };
  },
  async created() {
    if (UserSettingsService.has('secondEquipmentsFilter_filter_open', this.user.id)) {
      this.filter = ['p1'];
    }
    const exec = await executions.getExecutionsWithPagination(1, 1000000, null, 'name', false);
    if (exec.data) {
      this.executions = exec.data.items;
    }
    const org = await regApi.getDispatchCenters();
    if (org.data) {
      this.organizations = org.data;
    }
    const techAdmOrgs = await organizationsApi.getOrganizationSearchByNameAndRole('Технологическое управление');
    if (techAdmOrgs.data) {
      this.techAdministrationOrganizations = techAdmOrgs.data;
    }
    const techManOrgs = await organizationsApi.getOrganizationSearchByNameAndRole('Технологическое ведение');
    if (techManOrgs.data) {
      this.techManagementOrganizations = techManOrgs.data;
    }
    const dvOrgs = await organizationsApi.getOrganizationSearchByNameAndRole('Диспетчерское ведение');
    if (dvOrgs.data) {
      this.dvOrganizations = dvOrgs.data;
    }
    const duOrgs = await organizationsApi.getOrganizationSearchByNameAndRole('Диспетчерское управление');
    if (duOrgs.data) {
      this.duOrganizations = duOrgs.data;
    }
  },
  computed: {
    ...mapGetters('dictionaries', ['secondEquipmentPsrTypes', 'registrationGroupsWithNotMfu', 'decommissionedSE', 'operationalZones']),
    ...mapGetters('identity', ['user', 'userHasRoleGroup'])
  },
  methods: {
    handleChange(val) {
      if (val.find((el) => el === 'p1')) {
        UserSettingsService.set('secondEquipmentsFilter_filter_open', true, this.user.id);
      } else {
        UserSettingsService.delete('secondEquipmentsFilter_filter_open', this.user.id);
      }
      const objectLength = Object.keys(val);
      if (objectLength.length > 0) {
        // this.filterModel.isActive = true;
        this.$emit('active-change', true);
       } else {
         // this.filterModel.isActive = false;
        this.$emit('active-change', false);
      }
    },
    clearFilters() {
      this.$emit('clear-filters');
    }
  }
};
</script>


<style scoped lang="scss">
.filter--container{
  align-items: flex-start !important;
  span {
    min-width: 8rem;
  }
}
.middle-align{
  align-items:center !important;
}
</style>

