import axios from 'axios';

export default {
  getSecondEquipmentsWithPagination: (params) => axios.post('/api/SecondEquipments/GetSecondEquipmentsWithPagination/', params),
  getSecondEquipmentItem(id) {
    return axios.get('/api/SecondEquipments/GetSecondEquipmentItem', {
      params: {
        id
      }
    });
  },
  addSecondEquipment: (secondEquipment) => axios.post('/api/SecondEquipments', secondEquipment),
  updateSecondEquipment: (secondEquipment) => axios.put(`/api/SecondEquipments/${secondEquipment.id}`, secondEquipment),
  deleteSecondEquipment: (id) => axios.delete(`/api/SecondEquipments/${id}`),
  mergeSecondEquipments(mainId, secondaryIds) {
    return axios.post('/api/SecondEquipments/Merge', { mainId, secondaryIds });
  },
  getSecondEquipmentFactoryName(secondEquipmentId) {
    return axios.get('/api/SecondEquipments/GetSecondEquipmentFactoryName', {
      params: {
        secondEquipmentId
      }
    });
  },
};
